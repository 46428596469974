import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
//import scss
import "assets/css/pages/section2.scss";

//meta tags format
/*
import MetaTags from 'react-meta-tags';
const meta = {
	title: 'some title',
	description: 'description stuff',
	canonical: 'https://page',
	meta: {
		charset: 'utf-8',
		name: {
			keywords: 'military-friendly, realtor,other tags'
		}
	}
}

//goes in return or component
<DocumentMeta {...meta} />

*/

//Add 'Get matched with your agent' bar and CTA to bottom of section

export default class Section6 extends Component {
  constructor() {
    //do nothing
    super();
    console.log("loaded");
  }
  componentDidMount() {
    console.log("test");
  }
  render() {
    return (
      <div className={classNames("section", "ready-to-connect")}>
        <div className={classNames("row")}>
          <div className="col-12">
            <Card className={classNames("gray-background", "card")}>
              <CardBody className="card-body">
                <h2>Ready to connect with a military-friendly agent?</h2>
                <h3>Our team is waiting to match you up now.</h3>
                <Button
                  type="button"
                  color="secondary"
                  className={classNames("client-form-btn")}
                >
                  Get Connected
                </Button>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
